import React, { FC } from 'react';
import { useExperiments } from '@wix/yoshi-flow-editor';

import { NewBlockedMembersModal } from './NewBlockedMembersModal';
import { BlockedMembersModal } from './BlockedMembersModal';
import { Experiment, ModalType } from '../../../../../types';

export const BlockedMembersModalWrapper: FC<ModalType> = (props) => {
  const { experiments } = useExperiments();
  const shouldUseNewBlockedModal = experiments.enabled(
    Experiment.ShowBlockedMembersModalRedesign,
  );

  return shouldUseNewBlockedModal ? (
    <NewBlockedMembersModal {...props} />
  ) : (
    <BlockedMembersModal {...props} />
  );
};
